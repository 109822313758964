<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
      :style="{
        backgroundImage: `url(${backgroundImage})`
      }"
    >
      <!-- begin:: Content -->
      <h1
        class="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
        style="font-size: 150px;"
      >
        403
      </h1>
      <p class="font-size-h3 font-weight-light">
        Ooops! Accès a été refusé. Veuillez contacter l'administrateur.
      </p>
      <div>
        <router-link
          v-if="isAuthenticated"
          class="btn btn-warning"
          :to="{ name: 'dashboard' }"
        >
          <i class="fas fa-arrow-circle-left"></i>
          <span class="ml-3">Retour</span></router-link
        >
        <router-link v-else class="btn btn-warning" :to="{ name: 'Login' }">
          <i class="fas fa-arrow-circle-left"></i>
          <span class="ml-3">Retour</span></router-link
        >
      </div>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Forbidden",
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/error/bg6.jpg";
    },
    ...mapGetters({
      isAuthenticated: "authenticated"
    })
  }
};
</script>

<style scoped></style>
